import React from 'react'

const DirectionsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 217.205 217.205"
    style={{
      enableBackground: 'new 0 0 217.205 217.205',
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path d="M215.008 103.299 113.906 2.196a7.501 7.501 0 0 0-10.606 0L2.197 103.299a7.5 7.5 0 0 0 0 10.607L103.3 215.009a7.477 7.477 0 0 0 5.303 2.196 7.477 7.477 0 0 0 5.303-2.196l101.103-101.103a7.5 7.5 0 0 0-.001-10.607zm-106.405 95.799-90.496-90.496 90.496-90.496 90.496 90.496-90.496 90.496z" />
    <path d="M121.998 81.07H100.7c-11.633 0-21.098 9.465-21.098 21.099v39.406a7.5 7.5 0 0 0 7.5 7.5 7.5 7.5 0 0 0 7.5-7.5v-39.406a6.105 6.105 0 0 1 6.098-6.099h21.298l-6.217 6.216a7.5 7.5 0 0 0 10.606 10.607l19.021-19.02a7.502 7.502 0 0 0 0-10.608l-18.942-18.94a7.5 7.5 0 0 0-10.606 10.607l6.138 6.138z" />
  </svg>
)

export default DirectionsIcon

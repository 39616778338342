import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/layout'
import Review from '../../components/review'
import ReviewChartLayout from '../../components/review-chart-layout'
import { getSrc } from 'gatsby-plugin-image'

const ReviewPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const { genesisEverything, pullQuote, reviewImage, storeName } = frontmatter

  return (
    <Layout
      description={pullQuote}
      image={reviewImage && getSrc(reviewImage)}
      title={storeName}
    >
      <>
        <Review review={frontmatter} />
        {!genesisEverything && <ReviewChartLayout review={frontmatter} />}
      </>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        awards
        bagelIconColor
        bagelScore
        bagelReview
        bagelX
        bagelY
        borough
        creamCheeseIconColor
        creamCheeseNA
        creamCheeseScore
        creamCheeseReview
        creamCheeseX
        creamCheeseY
        genesisEverything
        isClosed
        locationLat
        locationLng
        neighborhood
        openSeaUrl
        pullQuote
        reviewImage {
          childImageSharp {
            gatsbyImageData(placeholder: DOMINANT_COLOR)
          }
        }
        storeIconColor
        storeNA
        storeName
        storeScore
        storeReview
        storeX
        storeY
        toppingIconColor
        toppingNA
        toppingX
        toppingY
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

export default ReviewPage

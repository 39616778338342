import { OutboundLink } from 'gatsby-plugin-google-analytics'
import React, { useState } from 'react'
import * as style from './style.module.css'

const OutsideLinkButton = ({ children, to }) => {
  const [pressed, setPressed] = useState(false)

  return (
    <OutboundLink
      className={`${style.button} ${pressed ? style.pressed : ''}`}
      href={to}
      onClick={() => {
        setPressed(false)
      }}
      onMouseDown={() => setPressed(true)}
      onMouseUp={() => setPressed(false)}
      rel="noopener noreferrer"
      target="_blank"
    >
      {children}
    </OutboundLink>
  )
}

export default OutsideLinkButton

import { OutboundLink } from 'gatsby-plugin-google-analytics'
import React, { useState } from 'react'
import * as style from './style.module.css'
import DirectionsIcon from '../directions-icon'
import { getMapsUrl } from '../../utils'

const MyMapLink = ({ position }) => {
  const [isHovered, setIsHovered] = useState(false)
  return (
    <div className={style.myMapLink}>
      <OutboundLink
        href={getMapsUrl(position)}
        onBlur={() => setIsHovered(false)}
        onFocus={() => setIsHovered(true)}
        onMouseOut={() => setIsHovered(false)}
        onMouseOver={() => setIsHovered(true)}
        rel="noopener noreferrer"
        target="_blank"
      >
        <div>
          <DirectionsIcon
            alt="directions"
            className={style.directionsIcon}
            fill={
              isHovered
                ? 'var(--text-accent-color)'
                : 'var(--text-primary-color)'
            }
            width="24px"
          />
        </div>
        <div className={style.myMapLinkText}>Get Directions</div>
      </OutboundLink>
    </div>
  )
}

export default MyMapLink

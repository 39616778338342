import React, { useEffect, useState } from 'react'
import * as style from './style.module.css'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Scorecard from '../scorecard'
import ImageViewer from '../image-viewer'
import FullSizeIcon from '../full-size-icon'
import OutsideLinkButton from '../outside-link-button'

const Review = ({ review }) => {
  const { borough, neighborhood, openSeaUrl, reviewImage, storeName } = review

  const [displayFullSizeImage, setDisplayFullSizeImage] = useState(false)

  useEffect(() => {
    if (displayFullSizeImage) {
      document.getElementsByTagName('body')[0].style.overflow = 'hidden'
    } else {
      document.getElementsByTagName('body')[0].style.overflow = 'visible'
    }
  }, [displayFullSizeImage])

  return (
    <>
      <article
        className={style.reviewDesktop}
        inert={displayFullSizeImage ? 'true' : undefined}
      >
        <div className={style.desktopHeadlineWrapper}>
          <div className={style.desktopPlaceholder} />
          <div
            className={`${style.headline} ${
              !!(!neighborhood || !borough) && style.noBorough
            }`}
          >
            <h1>{storeName}</h1>
            {!!(neighborhood && borough) && (
              <h2>{`${neighborhood}, ${borough}`}</h2>
            )}
          </div>
        </div>
        <div className={style.reviewBodyWrapper}>
          <div className={style.imgCol}>
            {reviewImage && (
              <div className={style.imgOverlay}>
                <GatsbyImage
                  alt={storeName}
                  as="figure"
                  className={style.reviewImgWrapper}
                  image={getImage(reviewImage)}
                  onClick={() => setDisplayFullSizeImage(true)}
                />
                <div className={style.overlayUi}>
                  <button
                    className={style.fullSizeButton}
                    onClick={() => setDisplayFullSizeImage(true)}
                  >
                    <FullSizeIcon />
                  </button>
                </div>
              </div>
            )}
            {openSeaUrl && (
              <OutsideLinkButton to={openSeaUrl}>
                View On OpenSea
              </OutsideLinkButton>
            )}
          </div>
          <div className={style.reviewBody}>
            <Scorecard review={review} />
            <div className={style.coffeeLinkWrapper}>
              <a
                className={style.coffeeLink}
                href="https://www.buymeacoffee.com/highleyvarlet"
                rel="noopener noreferrer"
                target="_blank"
              >
                Buy Mike and Jessi a coffee!
              </a>
            </div>
          </div>
        </div>
      </article>
      <article
        className={style.reviewMobile}
        inert={displayFullSizeImage ? 'true' : undefined}
      >
        <div className={style.reviewBody}>
          <div
            className={`${style.headline} ${
              !!(!neighborhood || !borough) && style.noBorough
            }`}
          >
            <h1>{storeName}</h1>
            {!!(neighborhood && borough) && (
              <h2>{`${neighborhood}, ${borough}`}</h2>
            )}
          </div>
          {reviewImage && (
            <div className={style.imgOverlay}>
              <GatsbyImage
                alt={storeName}
                as="figure"
                className={style.reviewImgWrapper}
                image={getImage(reviewImage)}
                onClick={() => setDisplayFullSizeImage(true)}
              />
              <div className={style.overlayUi}>
                <button
                  className={style.fullSizeButton}
                  onClick={() => setDisplayFullSizeImage(true)}
                >
                  <FullSizeIcon />
                </button>
              </div>
            </div>
          )}
          {openSeaUrl && (
            <div className={style.mobileButtonWrapper}>
              <OutsideLinkButton to={openSeaUrl}>
                View On OpenSea
              </OutsideLinkButton>
            </div>
          )}
          <Scorecard review={review} />
          <div className={style.coffeeLinkWrapper}>
            <a
              className={style.coffeeLink}
              href="https://www.buymeacoffee.com/highleyvarlet"
              rel="noopener noreferrer"
              target="_blank"
            >
              Buy Mike and Jessi a coffee!
            </a>
          </div>
        </div>
      </article>
      {displayFullSizeImage && (
        <ImageViewer
          onClose={() => setDisplayFullSizeImage(false)}
          reviewImage={reviewImage}
          storeName={storeName}
        />
      )}
    </>
  )
}

export default Review

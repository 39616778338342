export const getOverallScore = (...scores) =>
  Number(
    scores.reduce((prev, cur) => prev + cur, 0) / scores.filter(Boolean).length
  ).toFixed(2)

export const getMapsUrl = (destinationLoc) => {
  const url = new URL('https://www.google.com/maps/search/')
  url.search = new URLSearchParams({
    api: 1,
    query: destinationLoc.lat.toString() + ',' + destinationLoc.lng.toString(),
  })
  return url.href
}

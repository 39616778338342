import React from 'react'
import { getOverallScore } from '../../utils'
import MyMapLink from '../my-map-link'
import * as style from './style.module.css'

const hyperlinker = (str) => {
  const urlRegex =
    /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi

  const url = str?.match(urlRegex)
  if (!url) return [str]

  const strSplit = str.split(url[0])
  return [strSplit[0], url[0], strSplit[1]]
}

const Scorecard = ({ review }) => {
  const {
    awards,
    bagelReview,
    bagelScore,
    creamCheeseNA,
    creamCheeseReview,
    creamCheeseScore,
    locationLat,
    locationLng,
    storeNA,
    storeReview,
    storeScore,
  } = review

  const hyperlinkedBagelReview = hyperlinker(bagelReview)

  return (
    <div className={style.scorecard}>
      <div className={style.highlightsHeaderRow}>
        <div className={`${style.highlightsHeaderOuter} ${style.scoreBox}`}>
          <div className={style.highlightsHeaderInner}>
            <h2 className={style.highlightsHeader}>Total Score</h2>
          </div>
        </div>
        <div className={`${style.highlightsHeaderOuter} ${style.awardsBox}`}>
          <div className={style.highlightsHeaderInner}>
            <h2 className={style.highlightsHeader}>{'Ranks & Awards'}</h2>
          </div>
        </div>
      </div>
      <div className={style.highlightsRow}>
        <div className={`${style.highlightsContent} ${style.scoreBox}`}>
          <div className={style.score}>
            {getOverallScore(
              bagelScore,
              !creamCheeseNA && creamCheeseScore,
              !storeNA && storeScore
            )}{' '}
            / 5
          </div>
        </div>
        <div className={`${style.highlightsContent} ${style.awardsBox}`}>
          <div className={style.awards}>{awards}</div>
        </div>
      </div>
      {locationLat && locationLng && (
        <div className={style.directions}>
          <MyMapLink position={{ lat: locationLat, lng: locationLng }} />
        </div>
      )}
      <div className={style.reviewSections}>
        <section>
          <p>
            <span className={style.accent}>Store: </span>
            {storeReview}{' '}
            {!storeNA && <span className={style.accent}>{storeScore}</span>}
          </p>
        </section>
        <section>
          <p>
            <span className={style.accent}>Bagel: </span>
            {hyperlinkedBagelReview.length > 1 ? (
              <>
                {hyperlinkedBagelReview[0]}{' '}
                <a
                  href={hyperlinkedBagelReview[1]}
                  target="_blank"
                  rel="noreferrer"
                >
                  {hyperlinkedBagelReview[1]}
                </a>{' '}
                <span>{hyperlinkedBagelReview[2]}</span>
              </>
            ) : (
              bagelReview
            )}{' '}
            <span className={style.accent}>{bagelScore}</span>
          </p>
        </section>
        {creamCheeseReview && (
          <section>
            <p>
              <span className={style.accent}>Cream Cheese: </span>
              {creamCheeseReview}{' '}
              {!creamCheeseNA && (
                <span className={style.accent}>{creamCheeseScore}</span>
              )}
            </p>
          </section>
        )}
      </div>
    </div>
  )
}

export default Scorecard

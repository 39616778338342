// extracted by mini-css-extract-plugin
export var accent = "style-module--accent--SA6o3";
export var awards = "style-module--awards--y6sn4";
export var awardsBox = "style-module--awards-box--4Vh6S";
export var directions = "style-module--directions--Gf3Xu";
export var highlightsContent = "style-module--highlights-content--+u5mP";
export var highlightsHeader = "style-module--highlights-header--jHQKx";
export var highlightsHeaderInner = "style-module--highlights-header-inner--8WT7F";
export var highlightsHeaderOuter = "style-module--highlights-header-outer--DPP6m";
export var highlightsHeaderRow = "style-module--highlights-header-row--fVUeV";
export var highlightsRow = "style-module--highlights-row--32EDl";
export var reviewSections = "style-module--review-sections--Qxwrv";
export var score = "style-module--score--pJpS0";
export var scoreBox = "style-module--score-box--vJ3KX";
export var scorecard = "style-module--scorecard--8TFaC";
import React, { useCallback, useEffect } from 'react'
import * as style from './style.module.css'

const DialogOverlay = ({ children, onClose }) => {
  const onEsc = useCallback(
    (e) => {
      if (e.keyCode === 27) onClose?.()
    },
    [onClose]
  )

  useEffect(() => {
    document.addEventListener('keydown', onEsc, false)
    return () => {
      document.removeEventListener('keydown', onEsc, false)
    }
  }, [onEsc])

  const onKeyDown = (e) => {
    if (e.keyCode === 27) onClose?.()
  }

  return (
    <div
      className={style.dialogOverlay}
      onClick={onClose}
      onKeyDown={onKeyDown}
      role="presentation"
    >
      {children}
    </div>
  )
}

export default DialogOverlay

import React, { useMemo } from 'react'
import ReviewChart from '../review-chart'
import * as style from './style.module.css'

const ReviewChartLayout = ({ review }) => {
  const {
    bagelIconColor,
    bagelX,
    bagelY,
    creamCheeseIconColor,
    creamCheeseNA,
    creamCheeseX,
    creamCheeseY,
    isClosed,
    storeIconColor,
    storeNA,
    storeX,
    storeY,
    toppingIconColor,
    toppingNA,
    toppingX,
    toppingY,
  } = review

  const charts = useMemo(() => {
    return [
      !!(bagelX != null && bagelY != null) && {
        title: 'Bagel Size and Texture',
        values: [[bagelX, bagelY, bagelIconColor, undefined, isClosed]],
        xMaxLabel: 'X-Large',
        xMinLabel: 'Small',
        yMaxLabel: 'Crackly',
        yMinLabel: 'Chewy',
      },
      !!(!toppingNA && toppingX != null && toppingY != null) && {
        title: 'Seed and Salt Density',
        values: [[toppingX, toppingY, toppingIconColor, undefined, isClosed]],
        xMaxLabel: 'Topping Dense',
        xMinLabel: 'Topping Light',
        yMaxLabel: 'High Salt Ratio',
        yMinLabel: 'Low Salt Ratio',
      },
      !!(!creamCheeseNA && creamCheeseX != null && creamCheeseY != null) && {
        title: 'Spread Ripeness and Texture',
        values: [
          [
            creamCheeseX,
            creamCheeseY,
            creamCheeseIconColor,
            undefined,
            isClosed,
          ],
        ],
        xMaxLabel: 'Dairy Forward',
        xMinLabel: 'Dairy Latent',
        yMaxLabel: 'Fine Scallion',
        yMinLabel: 'Coarse Scallion',
      },
      !!(!storeNA && storeX != null && storeY != null) && {
        title: 'Store Style and Services',
        values: [[storeX, storeY, storeIconColor, undefined, isClosed]],
        xMaxLabel: 'Contemporary',
        xMinLabel: 'Classic',
        yMaxLabel: 'Variety of Services',
        yMinLabel: 'Focused Services',
      },
    ]
  }, [
    creamCheeseIconColor,
    creamCheeseNA,
    creamCheeseX,
    creamCheeseY,
    bagelIconColor,
    bagelX,
    bagelY,
    isClosed,
    storeIconColor,
    storeNA,
    storeX,
    storeY,
    toppingIconColor,
    toppingNA,
    toppingX,
    toppingY,
  ])

  return (
    <div className={style.reviewChartLayout}>
      {charts.filter(Boolean).map((chart) => (
        <div className={style.reviewChartWrapper} key={chart.title}>
          <ReviewChart {...chart} />
        </div>
      ))}
    </div>
  )
}

export default ReviewChartLayout

// extracted by mini-css-extract-plugin
export var bagelImgWrapper = "style-module--bagel-img-wrapper--ZUFFv";
export var coffeeLink = "style-module--coffee-link--rZrDF";
export var coffeeLinkWrapper = "style-module--coffee-link-wrapper--ejswl";
export var desktopHeadlineWrapper = "style-module--desktop-headline-wrapper--mQglV";
export var desktopPlaceholder = "style-module--desktop-placeholder--hHIY9";
export var fullSizeButton = "style-module--full-size-button--VWMjm";
export var headline = "style-module--headline--C34yb";
export var imgCol = "style-module--img-col--weCHw";
export var imgOverlay = "style-module--img-overlay--B10P-";
export var mobileButtonWrapper = "style-module--mobile-button-wrapper--kFnq5";
export var noBorough = "style-module--no-borough--4noUt";
export var overlayUi = "style-module--overlay-ui--fz76C";
export var reviewBody = "style-module--review-body--txQgD";
export var reviewBodyWrapper = "style-module--review-body-wrapper--6Sl1m";
export var reviewDesktop = "style-module--review-desktop--YtG2s";
export var reviewImgWrapper = "style-module--review-img-wrapper--cHPGI";
export var reviewMobile = "style-module--review-mobile--eMdji";
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React, { useCallback, useEffect } from 'react'
import DialogOverlay from '../dialog-overlay'
import * as style from './style.module.css'

const ImageViewer = ({ onClose, reviewImage, storeName }) => {
  const onEsc = useCallback(
    (e) => {
      if (e.keyCode === 27) onClose()
    },
    [onClose]
  )

  useEffect(() => {
    document.addEventListener('keydown', onEsc, false)
    return () => {
      document.removeEventListener('keydown', onEsc, false)
    }
  }, [onEsc])

  return (
    <DialogOverlay onClose={onClose}>
      <div className={style.uiWrapper}>
        <button className={style.overlayButton} onClick={onClose}>
          x
        </button>
      </div>
      <GatsbyImage
        alt={storeName}
        as="figure"
        className={style.imgWrapper}
        image={getImage(reviewImage)}
        objectFit="contain"
        // onClick={}
      />
    </DialogOverlay>
  )
}

export default ImageViewer
